<template>
  <v-container class="fill-height pt-8">
    <v-row class="fill-height" align="center" justify="center" no-gutters>
      <loading-symbol
        id="loadingSymbol"
        v-if="loading"
        :size="120"
        :color="$vuetify.theme.currentTheme.primary"
      />

      <v-col id="loadedContentContainer" v-else cols="12">
        <v-row no-gutters justify="center">
          <v-col cols="12">
            <v-row no-gutters justify="center">
              <v-col cols="auto">
                <v-icon
                  v-if="$vuetify.breakpoint.mdAndUp"
                  class="mr-4"
                  x-large
                  color="primary"
                  >{{
                    teamAvailable ? "mdi-account-group" : "mdi-phone-message"
                  }}</v-icon
                ></v-col
              >
              <v-col cols="auto">
                <p
                  id="titleText"
                  class="text-h4 ma-0 pa-0 text-center"
                  :class="$vuetify.breakpoint.smAndDown ? 'text-h5' : 'text-h4'"
                >
                  {{
                    teamAvailable
                      ? $t("authentication.joinTeamPage.teamExists.title")
                      : $t("authentication.joinTeamPage.noTeam.title")
                  }}
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="my-14" v-if="!teamAvailable">
            <p id="teamText" class="text-center pa-0 ma-0 text-h2 no-wrap-text">
              {{ $t("authentication.joinTeamPage.noTeam.contactAdminText") }}
            </p>
          </v-col>
          <v-col v-else cols="10" md="8" lg="6" xl="4" class="my-14">
            <v-hover
              v-for="(endUserTeam, index) in availableTeams"
              :key="endUserTeam.id"
              v-slot="{ hover }"
            >
              <v-card
                rounded="xl"
                :elevation="hover ? '10' : '2'"
                :color="hover ? 'selectedPrimary' : ''"
                @click="joinUserToTeam(endUserTeam)"
                class="text-center no-wrap-text"
                :class="cardPadding(index)"
              >
                <p
                  :id="'teamText' + endUserTeam.id"
                  :class="$vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h3'"
                  class="mb-0"
                >
                  {{ endUserTeam.name }}
                </p>
                <p
                  v-if="endUserTeam.size"
                  :id="'size' + endUserTeam.id"
                  class="text-body-1 mb-0 mt-3"
                >
                  {{
                    $tc(
                      "authentication.joinTeamPage.teamExists.memberCount",
                      endUserTeam.size,
                      { n: endUserTeam.size }
                    )
                  }}
                </p>
              </v-card>
            </v-hover>
          </v-col>
          <v-col v-if="!teamAvailable" cols="10" sm="6" xl="4">
            <v-btn
              id="signOutButton"
              color="primary"
              width="100%"
              @click="signOut()"
              :loading="loading"
              >{{
                $t("authentication.joinTeamPage.noTeam.actionButton")
              }}</v-btn
            >
          </v-col>
          <v-col cols="10" class="text-center text-h6 mt-6"
            >{{ $t("authentication.joinTeamPage.linkToCreateTeam.text") }}
          </v-col>
          <v-col cols="10" sm="6" xl="4" class="text-center text-body-1 mt-6">
            <v-btn
              color="primary"
              outlined
              id="createTeamLink"
              class="primary--text"
              width="100%"
              @click="$router.push('/create-team')"
            >
              {{ $t("authentication.joinTeamPage.linkToCreateTeam.link") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import {
  getActiveTeamForEndUserEmail,
  joinEndUserToTeamJoinTeamPage,
  userTeamMemberInformation
} from "@/customApi";
import LoadingSymbol from "../common/loaders/LoadingSymbol.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import TeamService from "@/services/teamService.js";

export default {
  name: "JoinTeam",
  components: {
    LoadingSymbol
  },
  props: {
    endUserTeams: Array
  },
  data() {
    return {
      loading: false,
      availableTeams: this.endUserTeams ?? []
    };
  },
  async mounted() {
    if (!this.endUserTeams) {
      await this.getTeamForEndUserEmail();
    }

    if (!this.teamMemberId) {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      this.setTeamMemberId(teamMberInfo.teamMemberId);
    }

    if (this.signedIn && this.userIsInTeam) {
      this.$router.push("/", () => {});
    }
  },
  computed: {
    ...mapGetters(["userEmail", "signedIn", "userIsInTeam", "teamMemberId"]),
    teamAvailable() {
      return this.availableTeams.length > 0;
    }
  },
  methods: {
    ...mapMutations(["setUserData", "setTeamMemberId"]),
    ...mapActions(["resetLoginState"]),
    async getTeamForEndUserEmail() {
      try {
        this.loading = true;
        let domainName = this.userEmail.split("@").pop();
        var endUserDto = {
          email: { value: this.userEmail },
          domainName: domainName
        };
        let endUserAndTeams = await getActiveTeamForEndUserEmail(endUserDto);
        if (endUserAndTeams) {
          this.availableTeams = [...endUserAndTeams.teams];
        }
      } catch (err) {
      } finally {
        this.loading = false;
      }
    },

    async joinUserToTeam(team) {
      try {
        this.loading = true;
        await joinEndUserToTeamJoinTeamPage(team.id);
        let teamMemberInfo = await userTeamMemberInformation();
        this.setUserData(teamMemberInfo);
        this.$router.push("/wellness", () => {});
      } catch (err) {
        this.loading = false;
      }
    },
    cardPadding(index) {
      let padding = this.$vuetify.breakpoint.smAndDown ? "pa-5" : "pa-12";
      if (index < this.availableTeams.length - 1) {
        padding += " mb-5";
      }
      return padding;
    },
    signOut() {
      Auth.signOut().then(() => {
        this.resetLoginState();
        this.$router.push("/", () => {});
      });
    }
  }
};
</script>
