var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height pt-8" },
    [
      _c(
        "v-row",
        {
          staticClass: "fill-height",
          attrs: { align: "center", justify: "center", "no-gutters": "" }
        },
        [
          _vm.loading
            ? _c("loading-symbol", {
                attrs: {
                  id: "loadingSymbol",
                  size: 120,
                  color: _vm.$vuetify.theme.currentTheme.primary
                }
              })
            : _c(
                "v-col",
                { attrs: { id: "loadedContentContainer", cols: "12" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "", justify: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _vm.$vuetify.breakpoint.mdAndUp
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "mr-4",
                                          attrs: {
                                            "x-large": "",
                                            color: "primary"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.teamAvailable
                                                ? "mdi-account-group"
                                                : "mdi-phone-message"
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-h4 ma-0 pa-0 text-center",
                                    class: _vm.$vuetify.breakpoint.smAndDown
                                      ? "text-h5"
                                      : "text-h4",
                                    attrs: { id: "titleText" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.teamAvailable
                                            ? _vm.$t(
                                                "authentication.joinTeamPage.teamExists.title"
                                              )
                                            : _vm.$t(
                                                "authentication.joinTeamPage.noTeam.title"
                                              )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.teamAvailable
                        ? _c(
                            "v-col",
                            { staticClass: "my-14", attrs: { cols: "12" } },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-center pa-0 ma-0 text-h2 no-wrap-text",
                                  attrs: { id: "teamText" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "authentication.joinTeamPage.noTeam.contactAdminText"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _c(
                            "v-col",
                            {
                              staticClass: "my-14",
                              attrs: { cols: "10", md: "8", lg: "6", xl: "4" }
                            },
                            _vm._l(_vm.availableTeams, function(
                              endUserTeam,
                              index
                            ) {
                              return _c("v-hover", {
                                key: endUserTeam.id,
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var hover = ref.hover
                                        return [
                                          _c(
                                            "v-card",
                                            {
                                              staticClass:
                                                "text-center no-wrap-text",
                                              class: _vm.cardPadding(index),
                                              attrs: {
                                                rounded: "xl",
                                                elevation: hover ? "10" : "2",
                                                color: hover
                                                  ? "selectedPrimary"
                                                  : ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.joinUserToTeam(
                                                    endUserTeam
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "mb-0",
                                                  class: _vm.$vuetify.breakpoint
                                                    .smAndDown
                                                    ? "text-h4"
                                                    : "text-h3",
                                                  attrs: {
                                                    id:
                                                      "teamText" +
                                                      endUserTeam.id
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(endUserTeam.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              endUserTeam.size
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "text-body-1 mb-0 mt-3",
                                                      attrs: {
                                                        id:
                                                          "size" +
                                                          endUserTeam.id
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$tc(
                                                              "authentication.joinTeamPage.teamExists.memberCount",
                                                              endUserTeam.size,
                                                              {
                                                                n:
                                                                  endUserTeam.size
                                                              }
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            }),
                            1
                          ),
                      !_vm.teamAvailable
                        ? _c(
                            "v-col",
                            { attrs: { cols: "10", sm: "6", xl: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "signOutButton",
                                    color: "primary",
                                    width: "100%",
                                    loading: _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.signOut()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "authentication.joinTeamPage.noTeam.actionButton"
                                      )
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center text-h6 mt-6",
                          attrs: { cols: "10" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "authentication.joinTeamPage.linkToCreateTeam.text"
                              )
                            ) + " "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-center text-body-1 mt-6",
                          attrs: { cols: "10", sm: "6", xl: "4" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary--text",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                id: "createTeamLink",
                                width: "100%"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("/create-team")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "authentication.joinTeamPage.linkToCreateTeam.link"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }